import React /*, { useEffect }*/ from 'react'
import { NextPageContext } from 'next'
import Error from '../components/Error'

interface ErrorPageProps {
  statusCode: number
}

/*const handleClientSide404Redirect = (statusCode: number) => {
  if (typeof window !== 'undefined') {
    const ssgPages = ['/specialist/', '/practice/']
    const path = window.location.pathname

    if (ssgPages.some((p) => path.includes(p)) && !path.endsWith(`/${statusCode}`)) {
      // Navigate client to not existing path to trigger native 404 handling.
      // Next-serverless is not working with manual notFound: true since
      // it's losing the context entirely.
      window.location.href = `${window.location.href}/${statusCode}`
    }
  }
}*/

const ErrorPage = ({ statusCode }: ErrorPageProps) => {
  return <Error statusCode={statusCode} />
}

ErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  let statusCode = 404
  if (res?.statusCode) {
    statusCode = res.statusCode
  } else if (err?.statusCode) {
    statusCode = err.statusCode
  }
  return { statusCode }
}

export default ErrorPage
